import { db } from "../indexedDB";
import store from "../store";
import axiosInstance from "../axiosInstance";
import { fetchAndStoreMyVehicleRunning } from "./myDataService";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/vehicles";
const STORE_NAME = "running_log";
const MY_LOG_STORE_NAME = "my_vehicle_running_logs";

export const fetchVehicleRunning = async (vehicleId) => {
  if (navigator.onLine) {
    const url =
      API_URL + `/v1/${vehicleId}/running-logs/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data.data;
        return data;
      }
    } catch (error) {
      store.commit("setError", { isError: true, error: error });
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
    }
  } else {
    return [];
  }
};

export const addVehicleRunning = async (vehicleId, item, isOthers = false) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/running-logs/new`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        if (!isOthers) {
          await fetchAndStoreMyVehicleRunning();
        }
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      // if (typeof error === 'object' && !isOthers) {
      //   await db.addOfflineChange({
      //     type: "addMyVehicleRunning",
      //     MY_LOG_STORE_NAME,
      //     item,
      //   });
      //   await db.addItem(MY_LOG_STORE_NAME, item);
      //   store.commit("setError", {
      //     isError: false,
      //     error: `Your changes are offline.`,
      //   });
      //   return "success";
      // } else {
        store.commit("setError", {
          isError: true,
          error: `Failed to add ${STORE_NAME}: ${error}`,
        });
      // }

      console.error(`Failed to add ${STORE_NAME}:`, error);
    }
  } else if (!isOthers) {
    // Store the change for later
    await db.addOfflineChange({
      type: "addMyVehicleRunning",
      MY_LOG_STORE_NAME,
      item,
    });
    await db.addItem(MY_LOG_STORE_NAME, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot create running log record offline.`,
    });
  }
};

export const editVehicleRunning = async (vehicleId, item, isOthers = false) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/running-logs/edit`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        if (!isOthers) {
          await fetchAndStoreMyVehicleRunning();
        }
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to edit ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to edit ${STORE_NAME}:`, error);
    }
  } else if (!isOthers) {
    // Store the change for later
    await db.addOfflineChange({
      type: "editMyVehicleRunning",
      STORE_NAME,
      item,
    });
    await db.editItem(STORE_NAME, item.uuid, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot create running log record offline.`,
    });
  }
};

export const deleteVehicleRunning = async (
  vehicleId,
  item,
  isOthers = false
) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/running-logs/delete`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        if (!isOthers) {
          await fetchAndStoreMyVehicleRunning();
        }
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to delete ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to delete ${STORE_NAME}:`, error);
    }
  } else if (!isOthers) {
    // Store the change for later
    await db.addOfflineChange({
      type: "deleteMyVehicleRunning",
      MY_LOG_STORE_NAME,
      item,
    });
    await db.deleteItem(MY_LOG_STORE_NAME, item.uuid);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot delete running log offline.`,
    });
  }
};

export const syncOfflineVehicleRunningChanges = async () => {
  if (!navigator.onLine) return;

  const offlineChanges = await db.getOfflineChanges();
  for (const change of offlineChanges) {
    store.commit("startSync", true);
    try {
      if (change.type === "addMyVehicleRunning") {
        await addVehicleRunning(change.item.vehicle_uuid, change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "editMyVehicleRunning") {
        await editVehicleRunning(change.item.vehicle_uuid, change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "deleteMyVehicleRunning") {
        await deleteVehicleRunning(change.item.vehicle_uuid, change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      store.dispatch("endSync");
    } catch (error) {
      console.error(`Failed to sync change for ${change.STORE_NAME}:`, error);
    }
  }
};

export const updateEditStatus = async (vehicleId, item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/running-logs/update-edit-status`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update Running Log date: ${error}`,
      });
      console.error(`Failed to update Running Log date:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update Running Log date offline.`,
    });
  }
};
